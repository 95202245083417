import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
import { calculateDiscountedPrice } from "helpers/get_discounted_price"
import {
  getAgencyService,
  getCartService,
  createCartService,
  updateCartService,
  deleteCartService,
  deleteMultipleCartService,
  receiveItemsService,
  searchProductService,
  getCurrentProductService,
  getShippingAddressService,
  getNotificationService,
  getWalletBalanceService,
  updateOrderStatusFromBankService
} from "services/agency.service"

export const getCartItems = createAsyncThunk(
  "getCart",
  async (params, { rejectWithValue }) => {
    return await getCartService(params, rejectWithValue)
  }
)

export const getWalletBalance = createAsyncThunk(
  "getWalletBalance",
  async (params, { rejectWithValue }) => {
    return await getWalletBalanceService(params, rejectWithValue)
  }
)

export const addToCart = createAsyncThunk("addToCart", async data => {
  const result = await createCartService(data)
  return result
})

export const updateOrderStatusFromBank = createAsyncThunk("updateOrderStatusFromBank", async data => {
  const result = await updateOrderStatusFromBankService(data)
  return result
})

export const updateCart = createAsyncThunk(
  "updateCart",
  async (data, { rejectWithValue }) => {
    const result = await updateCartService(
      data.cartId,
      { quantity: data.quantity },
      rejectWithValue
    )
    return result
  }
)

export const deleteCart = createAsyncThunk(
  "deleteCart",
  async (data, { rejectWithValue }) => {
    const result = await deleteCartService(
      parseInt(data),
      rejectWithValue
    )
    return result
  }
)

export const deleteMultipleCart = createAsyncThunk(
  "deleteMultipleCart",
  async (data, { rejectWithValue }) => {
    return await deleteMultipleCartService(data, rejectWithValue)
  }
)

export const getNotifications = createAsyncThunk(
  "getNotifications",
  async query => {
    return await getNotificationService(query)
  }
)

export const getProductList = createAsyncThunk("searchQuery", async query => {
  return await searchProductService(query)
})

export const getCurrentProduct = createAsyncThunk(
  "getCurrentProduct",
  async (params, { rejectWithValue }) => {
    return await getCurrentProductService(params, rejectWithValue)
  }
)

export const getShippingAddress = createAsyncThunk(
  "getShippingAddress",
  async (params, { rejectWithValue }) => {
    return await getShippingAddressService(params, rejectWithValue)
  }
)

export const getEWalletBalance = createAsyncThunk(
  "getEWalletBalance",
  async (params, { rejectWithValue }) => {}
)

export const receiveItems = createAsyncThunk(
  "receiveItems",
  async (data, { rejectWithValue }) => {
    const result = await receiveItemsService(data, rejectWithValue, {})
    return result
  }
)

export const getAgencyProfile = createAsyncThunk(
  "getAgencyProfile",
  async (id, {rejectWithValue}) => {
    // console.log("testing here")
    return await getAgencyService(id, rejectWithValue)
  }
)

export const clearViewProductReference = () => dispatch => {
  dispatch(slice.actions.clearViewProductReference())
}

export const selectedCloseItemInCompare = item => dispatch => {
  dispatch(slice.actions.selectedCloseItemInCompare(item))
}

export const addToCompare = item => dispatch => {
  dispatch(slice.actions.addToCompare(item))
}

export const updateItemsForCheckout = items => dispatch => {
  dispatch(slice.actions.updateItemsForCheckout(items))
}

export const clearCompareItems = () => dispatch => {
  dispatch(slice.actions.clearCompareItems())
}

export const globalSearchQuery = query => dispatch => {
  dispatch(slice.actions.globalSearchQuery(query))
}

export const categorySearchQuery = query => dispatch => {
  dispatch(slice.actions.categorySearchQuery(query))
}

// export const viewProduct = item => dispatch => {
//   dispatch(slice.actions.viewProduct(item))
// }

export const setCategorySelected = item => dispatch => {
  dispatch(slice.actions.setCategorySelected(item))
}

export const calculateNewBalance = item => dispatch => {
  dispatch(slice.actions.calculateNewBalance(item))
}

export const addBalance = item => dispatch => {
  dispatch(slice.actions.addBalance(item))
}

export const showVariantSelectorModal = options => dispatch => {
  dispatch(slice.actions.showVariantSelectorModal(options))
}

export const setVarianceSelected = (product, variant) => dispatch => {
  dispatch(slice.actions.setVarianceSelected({product: product, option: variant}))
}

export const addBuyNowProduct = item => dispatch => {
  dispatch(slice.actions.addBuyNowProduct(item))
}

export const removeBuyNowProduct = () => dispatch => {
  dispatch(slice.actions.removeBuyNowProduct(null))
}

// TODO: Add in exclude in redux persist
export const clearSearchProduct = () => dispatch => {
  dispatch(slice.actions.clearSearchProduct(""))
}

const initialState = {
  type: "",
  selectedCloseItemInCompare: null,
  agencyProfile: {},
  compareItems: [],
  myCart: [],
  itemsForCheckOut: [],
  myCartIncludes: [],
  productList: [],
  included: [],
  shipping: [],
  shippingInclude: [],
  currentProduct: null,
  currentProductLoading: false,
  viewProduct: null,
  viewProductIncluded: [],
  notifications: "test",
  eWalletBalance: 0,
  ewalletID: null,
  searchQuery: {
    product: "",
    category: "",
  },
  searchType: "",
  message: null,
  loading: false,
  addToCartSuccess: false,
  success: false,
  error: null,
  eWalletError: null,
  categorySelected: "",
  buyNowProduct: {
    data: null,
    isBuyNow: false,
  },
  isShowVariantSelectorModal: {
    isOpen: false,
    isAddToCart: false,
    processAddToCartCallback: null,
  },
  variantSelected: null,
  variantSelectedDetails: null,
}

const slice = createSlice({
  name: "Agency",
  initialState,
  reducers: {
    selectedCloseItemInCompare(state, action) {
      state.selectedCloseItemInCompare = action.payload
    },
    addToCompare(state, action) {
      const itemToAdd = action.payload

      if (!state.compareItems.some(item => parseInt(item.id) === parseInt(itemToAdd.id))) {
        if (state.compareItems.length >= 4) return
        state.compareItems.push(itemToAdd)
      } else {
        state.compareItems = state.compareItems.filter(
          current => parseInt(current.id) !== parseInt(itemToAdd.id)
        )
      }
    },
    clearCompareItems(state) {
      state.compareItems = []
    },
    updateItemsForCheckout(state, action) {
      state.itemsForCheckOut = action.payload
    },
    viewProduct(state, action) {
      state.currentProduct = action.payload
    },
    setCategorySelected(state, action) {
      state.categorySelected = action.payload
    },
    // mock up function
    calculateNewBalance(state, action) {
      state.eWalletBalance = state.eWalletBalance - action?.payload
    },
    addBalance(state, action) {
      state.eWalletBalance = state.eWalletBalance + action?.payload
    },
    getNotifications(state, action) {
      state.notifications = action.payload
    },
    addBuyNowProduct(state, action) {
      state.buyNowProduct = {
        ...state.buyNowProduct,
        isBuyNow: true,
        data: action.payload,
      }
    },
    removeBuyNowProduct(state, action) {
      state.buyNowProduct = {
        ...state.buyNowProduct,
        isBuyNow: false,
        data: action.payload,
      }
    },
    clearSearchProduct(state, action) {
      // state.searchQuery = action.payload
    },
    clearViewProductReference(state) {
      state.viewProduct = null
      state.viewProductIncluded = null
      state.variantSelected = null
      state.variantSelectedDetails = null
    },
    globalSearchQuery(state, action) {
      // console.log("action.payload")
      // console.log(action.payload)
      // console.log("action.payload")

      state.searchQuery = action.payload
      state.searchType = "all"
    },
    categorySearchQuery(state, action) {
      state.searchQuery = action.payload
      state.searchType = "category"
    },
    showVariantSelectorModal(state, action) {
      state.isShowVariantSelectorModal = action.payload
    },
    setVarianceSelected(state, action) {
      const option = action.payload.option
      const product = action.payload.product
      
      const discountPrice = calculateDiscountedPrice(
        option?.price,
        parseInt(product?.discount_percentage || 0)
      )
      if(option) {
        const details = {
          price: option?.price,
          discountedPrice: discountPrice,
          discountPercentage: product?.discount_percentage,
          stock: option.stock,
          imageLink: option?.image != "" ? option?.image : product?.product_images[0]?.image,
        }
        state.variantSelectedDetails = details
      } else {
        state.variantSelectedDetails = null
      }

      state.variantSelected = option
    }
  },
  extraReducers: {
    [getAgencyProfile.pending]: state => {
      state.loading = true
    },
    [getAgencyProfile.fulfilled]: (state, action) => {
      state.agencyProfile = action.payload
      state.success = true
    },
    [getAgencyProfile.rejected]: (state, action) => {
      state.error = action.error.message
    },

    [getWalletBalance.pending]: state => {
      state.loading = true
      state.eWalletBalance = 0
    },
    [getWalletBalance.fulfilled]: (state, action) => {
      state.eWalletBalance = action.payload.result?.data?.attributes?.balance
      state.ewalletID = action.payload.result?.data?.id
      // console.log("EWALLET API", action.payload.result?.data?.id)
      state.success = true
    },
    [getWalletBalance.rejected]: (state, action) => {
      state.error = action.error.message
    },
    [getShippingAddress.pending]: state => {
      state.loading = true
    },
    [getShippingAddress.fulfilled]: (state, action) => {
      state.shipping = action.payload.result.data
      state.shippingInclude = action.payload.result?.included
      state.success = true
    },
    [getShippingAddress.rejected]: (state, action) => {
      state.error = action.error.message
    },
    [getCartItems.pending]: state => {
      state.loading = true
    },
    [getCartItems.fulfilled]: (state, action) => {
      state.myCart = transformData(action.payload)
      state.myCartIncludes = action.payload.result.included
      state.myCartImages = action.payload.result.included
      state.success = true
    },
    [getCartItems.rejected]: (state, action) => {
      state.error = action.error.message
    },

    [addToCart.pending]: state => {
      state.addToCartSuccess = false
      ;(state.loading = true),
        //Remove buy now product
        (state.buyNowProduct = {
          ...state.buyNowProduct,
          isBuyNow: false,
          data: null,
        })
    },
    [addToCart.fulfilled]: (state, action) => {
      state.addToCartSuccess = true
    },
    [addToCart.rejected]: (state, action) => {
      state.addToCartSuccess = true
      state.error = action.error.message
    },
    [updateCart.pending]: state => {
      state.loading = true
    },
    [updateCart.fulfilled]: (state, action) => {
      state.success = true
    },
    [updateCart.rejected]: (state, action) => {
      state.error = action.error.message
    },
    [receiveItems.pending]: state => {
      state.loading = true
      state.success = false
      state.type = "receiveItems"
      state.error = null
    },
    [receiveItems.fulfilled]: (state, action) => {
      state.success = true
      state.loading = false
    },
    [receiveItems.rejected]: (state, action) => {
      state.error = action.error.message
      state.loading = false
    },

    [deleteCart.pending]: state => {
      state.loading = true
    },
    [deleteCart.fulfilled]: state => {
      state.success = true
    },
    [deleteCart.rejected]: (state, action) => {
      state.error = action.error.message
    },
    
    [deleteMultipleCart.pending]: state => {
      state.loading = true
    },
    [deleteMultipleCart.fulfilled]: state => {
      state.success = true
    },
    [deleteMultipleCart.rejected]: (state, action) => {
      state.error = action.error.message
    },

    [getProductList.pending]: state => {
      state.loading = true
    },
    [getProductList.fulfilled]: (state, action) => {
      state.productList = action.payload.result.data
      state.included = action.payload.result.included
      state.success = true
    },
    [getProductList.rejected]: (state, action) => {
      state.error = action.error.message
    },

    [getCurrentProduct.pending]: state => {
      state.currentProductLoading = true
    },
    [getCurrentProduct.fulfilled]: (state, action) => {
      state.viewProduct = transformData(action.payload)[0].attributes
      state.viewProductIncluded = action.payload.result.included
      state.success = true
      state.currentProductLoading = false
    },
    [getCurrentProduct.rejected]: (state, action) => {
      state.success = false
      state.currentProductLoading = false
      state.error = action.error.message
    },

    [getEWalletBalance.pending]: state => {
      state.loading = true
    },
    [getEWalletBalance.fulfilled]: (state, action) => {
      state.eWalletBalance = action.payload.result
      state.success = true
    },
    [getEWalletBalance.rejected]: (state, action) => {
      state.eWalletError = action.error.message
    },
    [getNotifications.pending]: state => {
      state.loading = true
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.getNotifications = action.payload.result.data
      state.getNotificationsIncluded = action.payload.result.included
      state.success = true
    },
    [getNotifications.rejected]: (state, action) => {
      state.error = action.error.message
    },
    ["PURGE"]: () => {
      return initialState
    },

    // builder
    //   .addCase(getProducts.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(getProducts.fulfilled, (state, action) => {
    //     state.products = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(getProducts.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(createProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(createProduct.fulfilled, (state, action) => {
    //     state.product = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(createProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(showProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(showProduct.fulfilled, (state, action) => {
    //     state.product = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(showProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(updateProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(updateProduct.fulfilled, (state, action) => {
    //     state.product = action.payload;
    //     state.success = true;
    //   })
    //   .addCase(updateProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })

    // builder
    //   .addCase(deleteProduct.pending, (state, action) => {
    //     state.loading = true;
    //   })
    //   .addCase(deleteProduct.fulfilled, (state, action) => {
    //     state.success = true;
    //   })
    //   .addCase(deleteProduct.rejected, (state, action) => {
    //     state.error = action.error.message
    //   })
  },
})

export const { reducer } = slice

export default slice

// HOW TO USE 👇

// import {
//   closeModal,
//   openModal,
// } from './slices/modals';
// import { useDispatch, useSelector } from './store';

// ACCESS STATE SLICE
// const {
//   isModalOpen,
// } = useSelector((state) => state.modals);

// TRIGGER DISPATCH / REDUCER FUNCTIONS
//const dispatch = useDispatch();
// dispatch(openModal());
