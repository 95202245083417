import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { useSelector, useDispatch } from "storeRtk"
import { persistor } from "storeRtk"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
// import user1 from "assets/images/users/avatar-1.jpg"
import avatarPlaceholder from "assets/images/users/account-avatar-placeholder.png"
import maleImg from "assets/images/male.png"
import femaleImg from "assets/images/female.png"
import { resetAuthStateThunk } from "slices/authentication"

const ProfileMenu = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userData } = useSelector(state => state.authReduxSlice)
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setUsername] = useState("--")

  const role = JSON.parse(localStorage.getItem("authUser"))?.role_type

  useEffect(() => {
    setUsername(`${userData?.Data?.fname} ${userData?.Data?.lname}`)
  }, [])

  // OLD
  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setUsername(obj.displayName)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setUsername(obj.username)
  //     }
  //   }
  // }, [props.success])

  const gender = userData?.Data?.user_gender || ""

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={gender === "Male" ? maleImg : femaleImg}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {role === "Agency" && (
            <DropdownItem
              tag="button"
              onClick={() => navigate(`/${role}/my-profile`)}
            >
              {" "}
              {/* <i className="bx bx-user font-size-16 align-middle me-1" /> */}
              {props.t("Profile")}{" "}
            </DropdownItem>
          )}
          {/* <DropdownItem
            tag="button"
            onClick={() => navigate(`/${role}/change-password`)}
          >
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("Change Password")}
          </DropdownItem> */}
          <DropdownItem
            tag="button"
            onClick={() => {
              console.log("TRIGGERED LOG OUT: ")
              // persistor.purge()
              // localStorage.clear()
              dispatch(resetAuthStateThunk());
              persistor.pause()
              persistor.flush().then(() => {
                return persistor.purge()
              })
              // OLD ---
              // window.location.replace(process.env.REACT_APP_PHILGEPS_DEV_URL)

              // Redirect to another domain
              window.location.href = `${process.env.REACT_APP_EMARKET_BASE_URL}/login`
              // window.location.href = process.env.REACT_APP_PHILGEPS_DEV_URL

              // Prevent user from going back to the previous page
              window.history.pushState(null, "", window.location.href)
              window.addEventListener("popstate", function () {
                window.history.pushState(null, "", window.location.href)
              })
            }}
          >
            {/* <i className="bx bx-wallet font-size-16 align-middle me-1" /> */}
            {props.t("Log Out")}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile || {}
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
