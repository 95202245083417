import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getCategoryService,
  getCategoriesService,
  updateCategoryService,
  createCategoryService,
  deleteCategoryService,
  updateActiveDeactivateCategoryService,
} from "services/category.service"
import { transformData } from "helpers/dataTransformer"

export const getCategories = createAsyncThunk(
  "/getCategories",
  async (params, { rejectWithValue }) => {
    return await getCategoriesService(params, rejectWithValue)
  }
)

export const getCategory = createAsyncThunk(
  "/getCategory",
  async (id, { rejectWithValue }) => {
    return await getCategoryService(id, rejectWithValue)
  }
)

export const getSubCategory = createAsyncThunk(
  "/getSubCategory",
  async (id, { rejectWithValue }) => {
    return await getCategoryService(id, rejectWithValue)
  }
)

export const addCategory = createAsyncThunk(
  "/addCategory",
  async (params, { rejectWithValue }) => {
    return await createCategoryService(params, rejectWithValue)
  }
)

export const updateCategory = createAsyncThunk(
  "/updateCategory",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateCategoryService(id, data, rejectWithValue, config)
  }
)

export const updateActiveDeactivateCategory = createAsyncThunk(
  "/updateActiveDeactivateCategory",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateActiveDeactivateCategoryService(id, data, rejectWithValue, config)
  }
)

export const deleteCategory = createAsyncThunk(
  "/deleteCategory",
  async (id, { rejectWithValue }) => {
    return await deleteCategoryService(id, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  category: {},
  categories: [],
  metaParentCategoryList: null,
  metaSubCategoryList: null,
  metaSubClassList: null,
  included: [],
  categoryTransform: {},
  subCategoryTransform: {},
  logs: initialLog,
  filterBySelectedCategories: [],
  filterBySelectedSubClassCategories: [],
}

const slice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    resetCategoryStore: state => {
      state.category = {}
      ;(state.included = []), (state.categories = []), (state.logs = initialLog)
    },
    saveFilterBySelectedCategories: (state, action) => {
      state.filterBySelectedCategories = action.payload
    },
    saveFilterBySelectedSubClassCategories: (state, action) => {
      state.filterBySelectedSubClassCategories = action.payload
    },
  },
  extraReducers: {
    [updateCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "updateCategory" }
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateCategory.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getCategories.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getCategories" }
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.result.data
      state.included = action.payload.result.included || []
      state.logs = { ...state.logs, success: true, loading: false }
      state.metaParentCategoryList = action.payload.result.meta
      state.metaSubCategoryList = action.payload.result.meta
      state.metaSubClassList = action.payload.result.meta
    },
    [getCategories.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            {`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [addCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "addCategory" }
    },
    [addCategory.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [addCategory.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getCategory" }
    },
    [getCategory.fulfilled]: (state, action) => {
      state.logs = { ...state.logs, success: true, loading: false }
      state.category = action.payload.result.data
      state.included = action.payload.result.included || []
      state.categoryTransform = transformData(action.payload);
    },
    [getCategory.rejected]: (state, action) => {
      state.logs = { ...state.logs, error: true, loading: false }
    },
    [getSubCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "getSubCategory" }
    },
    [getSubCategory.fulfilled]: (state, action) => {
      state.logs = { ...state.logs, success: true, loading: false }
      state.subCategoryTransform = transformData(action.payload);
    },
    [getSubCategory.rejected]: (state, action) => {
      state.logs = { ...state.logs, error: true, loading: false }
    },
    [deleteCategory.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "deleteCategory" }
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [deleteCategory.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { actions: categoryActions, reducer: categoryReducer } = slice

export const { resetCategoryStore, saveFilterBySelectedCategories, saveFilterBySelectedSubClassCategories} = categoryActions

export default slice
