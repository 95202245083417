import { del, get, post, put } from "helpers/api_helper"

const PRODUCTS_BASE_URL = "/product-categories"

export const getCategoriesService = (params, rejectWithValue) =>
  get(
    PRODUCTS_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const createCategoryService = (data, rejectWithValue) =>
  post(PRODUCTS_BASE_URL, data, {}, rejectWithValue)

export const getCategoryService = (params, rejectWithValue) =>
  get(`${PRODUCTS_BASE_URL}/${params}`, {}, rejectWithValue)

export const updateCategoryService = (id, data, rejectWithValue, config = {}) =>
  post(`${PRODUCTS_BASE_URL}/${id}`, data, config, rejectWithValue)

export const updateActiveDeactivateCategoryService = (id, data, rejectWithValue, config = {}) =>
  post(`/admin/product-category/${id}`, data, config, rejectWithValue)

export const deleteCategoryService = (id, rejectWithValue) =>
  del(`${PRODUCTS_BASE_URL}/${id}`, {}, rejectWithValue)
