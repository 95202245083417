import moment from "moment"
import PropTypes from "prop-types"
import { persistor } from "storeRtk"
import styled from "styled-components"
import { toast } from "react-toastify"
import SimpleBar from "simplebar-react"
import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"

//Notification Status Badges

//Agency
import ApprovedIcon from "assets/images/notification-bell/agency/Approved badge.svg"
import ReturnOrderIcon from "assets/images/notification-bell/agency/Return Order.svg"
import OrderPlacedIcon from "assets/images/notification-bell/agency/Order Placed.svg"
import OrderCancelledIcon from "assets/images/notification-bell/agency/Order Cancelled.svg"
import OutForDeliveryIcon from "assets/images/notification-bell/agency/Out for Delivery.svg"
import OrderProcessingIcon from "assets/images/notification-bell/agency/Order Processing.svg"
import AgencyOrderDeliveredIcon from "assets/images/notification-bell/agency/Order Delivered.svg"
//Merchant
import NoStockIcon from "assets/images/notification-bell/merchant/no-stock.svg"
import LowStockIcon from "assets/images/notification-bell/merchant/low-stock.svg"
import MerchantApprovedIcon from "assets/images/notification-bell/merchant/Approved badge.svg"
import MerchantOrderDeliveredIcon from "assets/images/notification-bell/merchant/order-delivered.svg"
import MerchantPaymentReceivedIcon from "assets/images/notification-bell/merchant/Payment Received.svg"
import MerchantPaymentReleasedIcon from "assets/images/notification-bell/merchant/Payment Released.svg"
import MerchantPaymentOnProcessIcon from "assets/images/notification-bell/merchant/Payment On Process.svg"
//Admin
import AdminAgencyOrderCollectedIcon from "assets/images/notification-bell/admin/order-collected.svg"
import AdminAgencyOrderPlacedIcon from "assets/images/notification-bell/admin/agency-order-placed.svg"
import AdminProductForApprovalIcon from "assets/images/notification-bell/admin/product-for-approval.svg"
import AdminAgencyOrderReceivedIcon from "assets/images/notification-bell/admin/agency-order-deliveredOrReceived.svg"
import AdminAgencyOrderDeliveredIcon from "assets/images/notification-bell/admin/agency-order-deliveredOrReceived.svg"

// Components
import { logoutState } from "slices/AuthUser"

//i18n
import { withTranslation } from "react-i18next"

// REDUX
import { useSelector, useDispatch } from "storeRtk"
import {
  getNotifications,
  updateNotifications,
} from "slices/agency-notifications" // agency
import {
  getMerchantNotifications,
  updateMerchantNotifications,
} from "slices/merchant-notifications" // merchant

import {
  getAdminNotifications,
  updateAdminNotifications,
} from "slices/admin-notifications" // admin

//ENUMS
import { orderStatusENUMS } from "constants/agency"

const role = JSON.parse(localStorage.getItem("authUser"))?.role_type

const NotificationDropdown = props => {
  // VARIABLES
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { agencyNotificationsArr, logs: agencyLogs } = useSelector(
    state => state.agencyNotificationsSlice
  )
  const { merchantNotificationsArr, logs: merchantLogs } = useSelector(
    state => state.merchantNotificationsSlice
  )
  const { adminNotificationsArr, logs: adminLogs } = useSelector(
    state => state.adminNotificationsSlice
  )

  const { userData } = useSelector(state => state.authReduxSlice)
  const scrollableNodeRef = useRef()
  const [notifData, setNotifData] = useState([])
  const [notifDataLimit3, setNotifDataLimit3] = useState([])
  const [menu, setMenu] = useState(false)

  // FUNCTIONS
  const handleGetAgencyNotifications = () => {
    dispatch(getNotifications())
  }

  const handleGetMerchantNotifications = () => {
    dispatch(getMerchantNotifications())
  }

  const handleGetAdminNotifications = () => {
    dispatch(getAdminNotifications())
  }

  const transformOrderStatus = (
    orderStatus,
    orderId,
    agencyName,
    isLowStock,
    isNoStock,
    isDelivered,
    merchantNotifIsReceived,
    merchantNotifIsReleased,
    merchantNotifIsOnProcess,
    merchantNotifIsOrderApproved,
    adminNotifProductForApproval,
    adminNotifAgencyOrderDelivered,
    adminNotifAgencyOrderPlaced,
    adminNotifAgencyOrderReceived,
    adminNotifAgencyOrderCollected
  ) => {
    if (userData?.role_type === "Agency") {
      if (orderStatus === "pending") {
        return `Your order no. ${orderId} is placed`
      } else if (orderStatus === "order_processing") {
        return `Your order no. ${orderId} is being processed`
      } else if (orderStatus === "out_for_delivery") {
        return `Your order no. ${orderId} is being delivered`
      } else if (orderStatus === "delivered") {
        return `Your order no. ${orderId} has been delivered`
      } else if (orderStatus === "cancelled") {
        return `Your order no. ${orderId ?? "--"} has been cancelled`
      } else if (orderStatus === "approved") {
        return `Your order no. ${orderId ?? "--"} has been approved`
      } else {
        return "Resource not found. 404 API error"
      }
    } else if (userData?.role_type === "Merchant") {
      if (orderStatus === "pending") {
        return `${agencyName} placed an order`
      } else if (orderStatus === "order_processing") {
        return `${agencyName} is being processed`
      } else if (orderStatus === "out_for_delivery") {
        return `${orderId} is being delivered`
      } else if (orderStatus === "delivered") {
        return `${agencyName} has been delivered`
      } else if (orderStatus === "cancelled") {
        return `Your order no. ${orderId} has been cancelled`
      } else if (merchantNotifIsOrderApproved) {
        return `Your order no. ${orderId ?? "--"} has been approved`
      } else if (isLowStock) {
        return "Your product is now on minimum"
      } else if (isNoStock) {
        return "Your product is now out of stock"
      } else if (isDelivered) {
        return `Your order no. ${orderId ?? "--"} has been delivered`
      } else if (merchantNotifIsReceived) {
        return `The payment has been received by the admin.`
      } else if (merchantNotifIsReleased) {
        return `The payment has been released by the admin.`
      } else if (merchantNotifIsOnProcess) {
        return `The payment is being processed by the admin.`
      } else {
        return "Resource not found. 404 API error"
      }
    } else if (userData?.role_type === "Admin") {
      if (adminNotifProductForApproval) {
        return `Merchant added a new item for approval.` // ADMINNOTIF: Merchant added a new item for approval
      } else if (adminNotifAgencyOrderPlaced) {
        return `${agencyName ?? "--"} placed an order.` // ADMINNOTIF: Agency 1 placed an order
      } else if (adminNotifAgencyOrderDelivered) {
        return `${agencyName ?? "--"} order has been delivered.` // ADMINNOTIF: Agency 1 order has been delivered
      } else if (adminNotifAgencyOrderReceived) {
        return `${agencyName ?? "--"} order has been received.` // ADMINNOTIF: Agency 2 order has been received
      } else if (adminNotifAgencyOrderCollected) {
        return `${agencyName ?? "--"} order has been collected.` // ADMINNOTIF: Agency 2 order has been collected
      } else {
        return "Resource not found. 404 API error"
      }
    }
  }

  const getNotifBadge = (
    orderStatus,
    isLowStock,
    isNoStock,
    isDelivered,
    merchantNotifIsReceived,
    merchantNotifIsReleased,
    merchantNotifIsOnProcess,
    merchantNotifIsOrderApproved,
    adminNotifProductForApproval,
    adminNotifAgencyOrderDelivered,
    adminNotifAgencyOrderPlaced,
    adminNotifAgencyOrderReceived,
    adminNotifAgencyOrderCollected
  ) => {
    if (userData?.role_type === "Agency") {
      if (orderStatus === "pending") {
        return OrderPlacedIcon // Your order is placed
      } else if (orderStatus === "order_processing") {
        return OrderProcessingIcon //Your order is being processed
      } else if (orderStatus === "out_for_delivery") {
        return OutForDeliveryIcon //Your order is being delivered
      } else if (orderStatus === "delivered") {
        return AgencyOrderDeliveredIcon //Your order has been delivered
      } else if (orderStatus === "cancelled") {
        return OrderCancelledIcon //Your order has been cancelled
      } else if (orderStatus === "return_item") {
        return ReturnOrderIcon //Your order is being returned
      } else if (orderStatus === "approved") {
        return ApprovedIcon // Your order has been approved
      } else {
        return "bx-error-alt" // Resource not found. 404 API error
      }
    } else if (userData?.role_type === "Merchant") {
      if (orderStatus === "pending") {
        return OrderPlacedIcon // placed an order
      } else if (orderStatus === "order_processing") {
        return OrderProcessingIcon //Your order is being processed FUTURE RELEASE
      } else if (orderStatus === "out_for_delivery") {
        return OutForDeliveryIcon //Your order is being delivered FUTURE RELEASE
      } else if (orderStatus === "cancelled") {
        return OrderCancelledIcon //Your order has been cancelled FUTURE RELEASE
      } else if (merchantNotifIsOrderApproved) {
        return MerchantApprovedIcon //Order has been approved
      } else if (orderStatus === "return_item") {
        return ReturnOrderIcon //Your order is being returned FUTURE RELEASE
      } else if (isLowStock) {
        return LowStockIcon // Your product is now on minimum
      } else if (isNoStock) {
        return NoStockIcon // Your product is now out of stock
      } else if (isDelivered) {
        return MerchantOrderDeliveredIcon //Your order has been delivered FUTURE RELEASE
      } else if (merchantNotifIsReceived) {
        return MerchantPaymentReceivedIcon // Payment has been received
      } else if (merchantNotifIsReleased) {
        return MerchantPaymentReleasedIcon // Payment has been released
      } else if (merchantNotifIsOnProcess) {
        return MerchantPaymentOnProcessIcon // Payment is being process
      } else {
        return "bx-error-alt" // Resource not found. 404 API error
      }
    } else if (userData?.role_type === "Admin") {
      if (adminNotifAgencyOrderPlaced) {
        return AdminAgencyOrderPlacedIcon // ADMINNOTIF: Agency 1 placed an order
      } else if (adminNotifProductForApproval) {
        return AdminProductForApprovalIcon //ADMINNOTIF: Merchant added a new item for approval
      } else if (adminNotifAgencyOrderDelivered) {
        return AdminAgencyOrderDeliveredIcon //ADMINNOTIF: Agency 1 order has been delivered
      } else if (adminNotifAgencyOrderReceived) {
        return AdminAgencyOrderReceivedIcon // ADMINNOTIF: Agency 2 order has been received
      } else if (adminNotifAgencyOrderCollected) {
        return AdminAgencyOrderCollectedIcon // ADMINNOTIF: Agency 2 order has been collected
      } else {
        return "bx-error-alt" // Resource not found. 404 API error
      }
    }
  }

  const getNotifBadgeColor = (orderStatus, isLowStock, isNoStock) => {
    if (orderStatus === "pending") {
      return "warning" // Your order is placed
    } else if (orderStatus === "order_processing") {
      return "warning" //Your order is being processed
    } else if (orderStatus === "out_for_delivery") {
      return "primary" //Your order is being delivered
    } else if (orderStatus === "delivered") {
      return "primary" //Your order has been delivered
    } else if (orderStatus === "cancelled") {
      return "danger" //Your order has been cancelled
    } else if (orderStatus === "return_item") {
      return "danger" //Your order is being returned
    } else if (isLowStock) {
      return "primary" // Your product is now on minimum
    } else if (isNoStock) {
      return "danger" // Your product is now out of stock
    } else {
      return "danger" // Resource not found. 404 API error
    }
  }

  const forcedLogout = () => {
    toast.error(
      "Session Expired, after 5 secs will redirect you to log-in...",
      { hideProgressBar: true }
    )

    setTimeout(() => {
      localStorage.clear()
      persistor.pause()
      persistor.flush().then(() => {
        return persistor.purge()
      })

      dispatch(logoutState())
      window.location.href = process.env.REACT_APP_PHILGEPS_DEV_URL
    }, [3000])
  }

  const getLogs = currentLogs => {
    if (currentLogs) {
      const { error, loading, message } = currentLogs

      if (
        error &&
        loading === false &&
        error.length > 0 &&
        +error[0]?.code === 403
      ) {
        forcedLogout()
      } else {
        toast.error(message, { hideProgressBar: true })
      }
    }
  }

  useEffect(() => {
    console.log("TRIGGER ON MOUNT: ", userData?.role_type)
    // On Mount GET notifications
    if (userData?.role_type === "Agency") {
      // console.log(`${role ?? "--"} On Mount GET Notif`)
      handleGetAgencyNotifications()
      // return () => clearInterval(notifInterval)
    } else if (userData?.role_type === "Merchant") {
      // console.log(`${userData?.role_type ?? "--"} On Mount GET Notif`)
      handleGetMerchantNotifications()
    } else if (userData?.role_type === "Admin") {
      // console.log(`${userData?.role_type ?? "--"} On Mount GET Notif`)
      handleGetAdminNotifications()
    } else {
      // console.log(`${role ?? "--"} On Mount GET Notif`)
    }
  }, [userData?.role_type])

  useEffect(() => {
    // Trigger Interval
    let notifInterval
    if (userData?.role_type === "Agency") {
      // console.log(`${role ?? "--"} Notifications Agency Interval Activated`)
      notifInterval = setInterval(handleGetAgencyNotifications, 10000) // 10secs previously 2sec
      // return () => clearInterval(notifInterval)
    } else if (userData?.role_type === "Merchant") {
      // console.log(`${role ?? "--"} Notifications Merchant Interval Activated`)
      notifInterval = setInterval(handleGetMerchantNotifications, 10000) // 2secs previously 2sec
    } else if (userData?.role_type === "Admin") {
      // console.log(`${role ?? "--"} Notifications Admin Interval Activated`)
      notifInterval = setInterval(handleGetAdminNotifications, 10000) // 2secs previously 2sec
    } else {
      // console.log(`${role ?? "--"} Notifications is not available`)
    }

    // Clean Up
    return () => {
      // console.log("Notif Interval Deactivated")
      clearInterval(notifInterval)
    }
  }, [])

  useEffect(() => {
    if (userData?.role_type === "Agency") {
      const filteredAgencyNotificationsArr = agencyNotificationsArr.filter(
        notif => notif?.attributes?.read === false
      )

      const showOnly3NotifsInContainer = filteredAgencyNotificationsArr.slice(
        0,
        3
      )

      // console.log("AGENCY NOTIFICATIONS: ", filteredAgencyNotificationsArr)
      setNotifDataLimit3(showOnly3NotifsInContainer)
      setNotifData(filteredAgencyNotificationsArr)
    } else if (userData?.role_type === "Merchant") {
      const filteredMerchantNotificationsArr = merchantNotificationsArr.filter(
        notif => notif?.attributes?.read === false
      )

      const showOnly3NotifsInContainer = filteredMerchantNotificationsArr.slice(
        0,
        3
      )

      // console.log("MERCHANT NOTIFICATIONS: ", filteredMerchantNotificationsArr)
      setNotifDataLimit3(showOnly3NotifsInContainer)
      setNotifData(filteredMerchantNotificationsArr)
    } else if (userData?.role_type === "Admin") {
      const filteredAdminNotificationsArr = adminNotificationsArr.filter(
        notif => notif?.attributes?.read === false
      )

      const showOnly3NotifsInContainer = filteredAdminNotificationsArr.slice(
        0,
        3
      )

      // console.log("Admin NOTIFICATIONS: ", filteredAdminNotificationsArr)
      setNotifDataLimit3(showOnly3NotifsInContainer)
      setNotifData(filteredAdminNotificationsArr)
    }
  }, [agencyNotificationsArr, merchantNotificationsArr, adminNotificationsArr])

  useEffect(() => {
    switch (userData?.role_type) {
      case "Admin":
        getLogs(adminLogs)
        break
      case "Merchant":
        getLogs(merchantLogs)
        break
      case "Agency":
        getLogs(agencyLogs)
        break
      default:
        break
    }
  }, [adminLogs, merchantLogs, agencyLogs, userData?.role_type])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        {/* -----------------THE BELL ICON----------------- 👇*/}
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={`bx bx-bell${notifData.length > 0 ? " bx-tada" : ""}`} //dynamic bell animation
          />
          {notifData.length > 0 && (
            <BadgeCounter className="badge bg-danger rounded-pill">
              {notifData.length}
            </BadgeCounter>
          )}
        </DropdownToggle>

        {/* -----------------CONTENT TITLE----------------- 👇*/}
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 mb-2"> {props.t("Notifications")} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#" className="small">
                  View All
                </a>
              </div> */}
            </Row>
          </div>

          {/* -----------------CONTENT BODY----------------- 👇 */}
          <StyledSimplebar
            scrollableNodeProps={{ ref: scrollableNodeRef }}
            isnodata={String(notifData?.length)}
            role={role}
          >
            {notifData?.length === 0 ? (
              <NoNotificationsWrapper>No Data Available</NoNotificationsWrapper>
            ) : (
              notifDataLimit3.map((notif, index) => {
                if (notif?.attributes?.read === false) {
                  return (
                    <NotificationItem
                      key={index}
                      // to={`/agency/order-status/${notif?.attributes?.data?.order_id}`}
                      className="text-reset notification-item"
                    >
                      <NotificationRow>
                        <NotifCol>
                          {/* ------------ DYNAMIC BADGE ------------ 👇*/}
                          <NotifBadgeWrapper className="avatar-xs">
                            {orderStatusENUMS.includes(
                              notif?.attributes?.data?.order_status
                            ) ||
                            notif?.attributes?.data?.product_status ===
                              "for_approval" ? (
                              <img
                                src={getNotifBadge(
                                  notif?.attributes?.data?.order_status ?? "", // orderStatus
                                  // ---------------------------------------------MERCHANT NOTIFICATIONS
                                  notif?.attributes?.data?.type === "low_stock", // MERCHANTNOTIF: product in minimum stock
                                  notif?.attributes?.data?.type === "no_stock", // MERCHANTNOTIF: product in minimum stock
                                  notif?.attributes?.data?.type === "delivered", // MERCHANTNOTIF: order has been delivered
                                  notif?.attributes?.data?.type ===
                                    "received" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Payment has been received
                                  notif?.attributes?.data?.type ===
                                    "released" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Payment has been released
                                  notif?.attributes?.data?.type ===
                                    "on_process" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Payment is being processed
                                  notif?.attributes?.data?.type ===
                                    "approved" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Your order has been approved
                                  // ---------------------------------------------ADMIN NOTIFICATIONS
                                  notif?.attributes?.data?.type ===
                                    "for_approval" &&
                                    notif?.attributes?.data?.product_status ===
                                      "for_approval", // ADMINNOTIF: Merchant added a new item for approval
                                  notif?.attributes?.data?.type ===
                                    "delivered" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // ADMINNOTIF: Agency 1 order has been delivered
                                  notif?.attributes?.data?.type === "pending" &&
                                    notif?.attributes?.data?.order_status ===
                                      "pending", // ADMINNOTIF: Agency 1 placed an order
                                  notif?.attributes?.data?.type ===
                                    "received" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // ADMINNOTIF: Agency 2 order has been received
                                  notif?.attributes?.data?.type ===
                                    "collected" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved" // ADMINNOTIF: Agency 2 order has been collected
                                )}
                                alt="notifBadge"
                                width="32"
                                height="32"
                              />
                            ) : (
                              <NotificationBadge
                                className={`avatar-title bg-${getNotifBadgeColor(
                                  notif?.attributes?.data?.order_status,
                                  // ---------------------------------------------MERCHANT NOTIFICATIONS
                                  notif?.attributes?.data?.type === "low_stock", // MERCHANTNOTIF: product in minimum stock
                                  notif?.attributes?.data?.type === "no_stock", // MERCHANTNOTIF: product in minimum stock
                                  notif?.attributes?.data?.type === "delivered", // MERCHANTNOTIF: order has been delivered
                                  notif?.attributes?.data?.type ===
                                    "received" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Payment has been received
                                  notif?.attributes?.data?.type ===
                                    "released" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Payment has been released
                                  notif?.attributes?.data?.type ===
                                    "on_process" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Payment is being processed
                                  notif?.attributes?.data?.type ===
                                    "approved" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // Your order has been approved
                                  // ---------------------------------------------ADMIN NOTIFICATIONS
                                  notif?.attributes?.data?.type ===
                                    "for_approval" &&
                                    notif?.attributes?.data?.product_status ===
                                      "for_approval", // ADMINNOTIF: Merchant added a new item for approval
                                  notif?.attributes?.data?.type ===
                                    "delivered" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // ADMINNOTIF: Agency 1 order has been delivered
                                  notif?.attributes?.data?.type === "pending" &&
                                    notif?.attributes?.data?.order_status ===
                                      "pending", // ADMINNOTIF: Agency 1 placed an order
                                  notif?.attributes?.data?.type ===
                                    "received" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved", // ADMINNOTIF: Agency 2 order has been received
                                  notif?.attributes?.data?.type ===
                                    "collected" &&
                                    notif?.attributes?.data?.order_status ===
                                      "approved" // ADMINNOTIF: Agency 2 order has been collected
                                )} rounded-circle font-size-16`}
                              >
                                <i className={`bx bx-error-alt`} />
                              </NotificationBadge>
                            )}
                          </NotifBadgeWrapper>
                        </NotifCol>
                        <NotifCol type="description">
                          <div className="flex-grow-1">
                            <NotificationTitle className="mt-0 mb-1">
                              {transformOrderStatus(
                                notif?.attributes?.data?.order_status ?? "--", //orderStatus
                                notif?.attributes.data?.order_id ?? "--", //orderId
                                notif?.attributes?.data?.agency_department ??
                                  "--", //agencyName
                                // ---------------------------------------------MERCHANT NOTIFICATIONS
                                notif?.attributes?.data?.type === "low_stock", // MERCHANTNOTIF: product in minimum stock
                                notif?.attributes?.data?.type === "no_stock", // MERCHANTNOTIF: product in minimum stock
                                notif?.attributes?.data?.type === "delivered", // MERCHANTNOTIF: order has been delivered
                                notif?.attributes?.data?.type === "received" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved", // Payment has been received
                                notif?.attributes?.data?.type === "released" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved", // Payment has been released
                                notif?.attributes?.data?.type ===
                                  "on_process" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved", // Payment is being processed
                                notif?.attributes?.data?.type === "approved" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved", // Your order has been approved
                                // ---------------------------------------------ADMIN NOTIFICATIONS
                                notif?.attributes?.data?.type ===
                                  "for_approval" &&
                                  notif?.attributes?.data?.product_status ===
                                    "for_approval", // ADMINNOTIF: Merchant added a new item for approval
                                notif?.attributes?.data?.type === "delivered" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved", // ADMINNOTIF: Agency 1 order has been delivered
                                notif?.attributes?.data?.type === "pending" &&
                                  notif?.attributes?.data?.order_status ===
                                    "pending", // ADMINNOTIF: Agency 1 placed an order
                                notif?.attributes?.data?.type === "received" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved", // ADMINNOTIF: Agency 2 order has been received
                                notif?.attributes?.data?.type === "collected" &&
                                  notif?.attributes?.data?.order_status ===
                                    "approved" // ADMINNOTIF: Agency 2 order has been collected
                              )}
                            </NotificationTitle>
                            <TypographyDescAndTime className="font-size-12 text-muted">
                              <NotificationDescription
                                className="mb-1"
                                onClick={() => {
                                  {
                                    /* ------------ DYNAMIC REDIRECTION AND READ NOTIF ------------ 👇*/
                                  }
                                  if (userData?.role_type === "Agency") {
                                    // AGENCY NOTIFS -------------
                                    // Redirect to Order Status Details
                                    navigate(
                                      `/agency/order-status/${notif?.attributes?.data?.order_id}`,
                                      { state: { notificationId: notif?.id } }
                                    )
                                    dispatch(
                                      updateNotifications({
                                        id: notif?.id,
                                        data: { read: true },
                                      })
                                    )
                                  } else if (
                                    userData?.role_type === "Merchant" // MERCHANT NOTIFS -------------
                                  ) {
                                    if (
                                      notif?.attributes?.data?.type ===
                                      "low_stock"
                                    ) {
                                      // Redirect to Order Status Details
                                      navigate(
                                        `/merchant/products/${notif?.attributes?.data?.product?.id}/edit`,
                                        {
                                          state: {
                                            notificationId: notif?.id,
                                          },
                                        }
                                      )
                                    } else if (
                                      notif?.attributes?.data?.type ===
                                        "released" &&
                                      notif?.attributes?.data?.order_status ===
                                        "approved" // Payment has been released
                                    ) {
                                      // Redirect to Merchant Payments List
                                      navigate(`/merchant/payments-list`, {
                                        state: {
                                          notificationId: notif?.id,
                                          selectedOrderId:
                                            notif?.attributes?.data?.order_id,
                                        },
                                      })
                                    } else if (
                                      notif?.attributes?.data?.type ===
                                        "received" &&
                                      notif?.attributes?.data?.order_status ===
                                        "approved" // Payment has been receivedPayment
                                    ) {
                                      // Redirect to Merchant Order History
                                      navigate(`/merchant/order-history-list`, {
                                        state: {
                                          notificationId: notif?.id,
                                          selectedOrderId:
                                            notif?.attributes?.data?.order_id,
                                        },
                                      })
                                    } else if (
                                      notif?.attributes?.data?.type ===
                                        "on_process" &&
                                      notif?.attributes?.data?.order_status ===
                                        "approved" // Payment is being processed
                                    ) {
                                      // Redirect to Merchant Payments List
                                      navigate(`/merchant/payments-list`, {
                                        state: {
                                          notificationId: notif?.id,
                                          selectedOrderId:
                                            notif?.attributes?.data?.order_id,
                                        },
                                      })
                                    } else {
                                      // Redirect to Order Status Details
                                      navigate(
                                        `/merchant/pending-order/${notif?.attributes?.data?.order_id}`,
                                        { state: { notificationId: notif?.id } }
                                      )
                                    }

                                    // Read Notif
                                    // console.log(
                                    //   "TRIGGERED READ NOTIF (PUT): ",
                                    //   notif?.id
                                    // )
                                    // dispatch(
                                    //   updateMerchantNotifications({
                                    //     id: notif?.id,
                                    //     data: { read: true },
                                    //   })
                                    // )
                                  } else if (userData?.role_type === "Admin") {
                                    // ADMIN NOTIFS -------------
                                    if (
                                      notif?.attributes?.data?.type ===
                                        "for_approval" &&
                                      notif?.attributes?.data
                                        ?.product_status === "for_approval"
                                    ) {
                                      // Redirect to Admin View Pending For Approval Page
                                      navigate(
                                        `/admin/product-listing/${notif?.attributes?.data?.product_id}`,
                                        {
                                          state: {
                                            notificationId: notif?.id,
                                          },
                                        }
                                      )
                                    } else if (
                                      notif?.attributes?.data?.type ===
                                        "pending" &&
                                      notif?.attributes?.data?.order_status ===
                                        "pending"
                                    ) {
                                      // Redirect to Admin View Pending Order
                                      navigate(
                                        `/admin/pending-order/${notif?.attributes?.data?.order_id}`,
                                        {
                                          state: {
                                            notificationId: notif?.id,
                                          },
                                        }
                                      )
                                    } else if (
                                      (notif?.attributes?.data?.type ===
                                        "delivered" &&
                                        notif?.attributes?.data
                                          ?.order_status === "approved") || // ADMINNOTIF: Agency 1 order has been delivered
                                      (notif?.attributes?.data?.type ===
                                        "received" &&
                                        notif?.attributes?.data
                                          ?.order_status === "approved") || // ADMINNOTIF: Agency 2 order has been received
                                      (notif?.attributes?.data?.type ===
                                        "collected" &&
                                        notif?.attributes?.data
                                          ?.order_status === "approved") // ADMINNOTIF: Agency 2 order has been collected
                                    ) {
                                      // Redirect to Admin View Pending Order
                                      navigate(
                                        `/admin/confirmed-order/${notif?.attributes?.data?.order_id}`,
                                        {
                                          state: {
                                            notificationId: notif?.id,
                                          },
                                        }
                                      )
                                    }

                                    dispatch(
                                      updateAdminNotifications({
                                        id: notif?.id,
                                        data: { read: true },
                                      })
                                    )
                                  }

                                  // Close dropdown
                                  setMenu(false)
                                }}
                              >
                                {/* ------------ DYNAMIC DESCRIPTION TEXT ------------ 👇*/}
                                {userData?.role_type === "Agency"
                                  ? "Click here to view your order status."
                                  : userData?.role_type === "Merchant" &&
                                    notif?.attributes?.data?.type ===
                                      "low_stock"
                                  ? "Click here to add more stocks."
                                  : userData?.role_type === "Merchant"
                                  ? "Click here to view the agency order."
                                  : userData?.role_type === "Admin" &&
                                    notif?.attributes?.data?.type ===
                                      "for_approval" &&
                                    notif?.attributes?.data?.product_status ===
                                      "for_approval"
                                  ? "Click here to view item." // ADMINNOTIF: Merchant added a new item for approval
                                  : userData?.role_type === "Admin"
                                  ? "Click here to view the agency order."
                                  : "Click here to view."}
                              </NotificationDescription>
                              <NotificationTime>
                                <i
                                  className="mdi mdi-clock-outline"
                                  style={{ marginRight: "5px" }}
                                />
                                {notif?.attributes?.updated_at
                                  ? moment(notif?.attributes?.updated_at)
                                      .local()
                                      .fromNow()
                                  : "--"}
                              </NotificationTime>
                            </TypographyDescAndTime>
                          </div>
                        </NotifCol>
                      </NotificationRow>
                    </NotificationItem>
                  )
                } else {
                  return ""
                }
              })
            )}
          </StyledSimplebar>

          {/* -----------------CONTENT FOOTER----------------- 👇 */}
          {notifData?.length > 3 && (
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to={`/${userData?.role_type?.toLowerCase()}/notifications`}
                onClick={() => setMenu(!menu)}
              >
                <i className="mdi mdi-arrow-right-circle me-1" />{" "}
                <span key="t-view-more">{props.t("View More..")}</span>
              </Link>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

// STYLED COMPONENTS 👇
const BadgeCounter = styled.span``

const NoNotificationsWrapper = styled.div`
  text-align: center;
`
const StyledSimplebar = styled(SimpleBar)`
  height: ${props =>
    props.isnodata === "0"
      ? "30px"
      : props.isnodata === "1"
      ? "90px"
      : props.isnodata === "2"
      ? "180px"
      : props.isnodata === "3"
      ? "270px"
      : props.isnodata === "0" && userData?.role_type === "Merchant"
      ? "40px"
      : props.isnodata === "1" && userData?.role_type === "Merchant"
      ? "100px"
      : props.isnodata === "2" && userData?.role_type === "Merchant"
      ? "190px"
      : props.isnodata === "3" && userData?.role_type === "Merchant"
      ? "280px"
      : "270px"};
  .simplebar-content-wrapper {
    box-sizing: content-box !important;
  }
  //
`
// const NotificationItem = styled(Link)``
const NotificationItem = styled.div``

const NotificationRow = styled.div`
  display: flex;
`
const NotifCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.type === "description" ? "start" : "center")};
  width: ${props => (props.type === "description" ? "300px" : "75px")};
`
const NotifBadgeWrapper = styled.div`
  min-width: 10px;
`
const NotificationBadge = styled.span``
const NotificationTitle = styled.h6`
  font-weight: 600;
  color: #2b2b2b !important;
  padding-right: 20px;
`

const TypographyDescAndTime = styled.div`
  padding-right: 50px;
`
const NotificationDescription = styled.p`
  color: #313d85 !important;
  font-weight: 500;
  cursor: pointer;
`
const NotificationTime = styled.p`
  margin-bottom: 7px;
`

// NOTIFICATION MATRICES: 👇
// BE      |      FE
// pending = "Order Placed" 👈 means approved by admin in Admin > Agency Orders Page
// order_processing = "On Process" 👈 means status is updated in Merchant > Active Orders Page
// out_for_delivery = "To Receive" 👈 means status is updated in Merchant > Active Orders Page
// delivered = "Delivered" 👈 means status is updated in Merchant > Active Orders Page
// cancelled = "Cancelled" 👈 means status is cancelled/decline in Agency > Order Status Page (order placed | pero wla pa to sa needs) & Admin > Agency Orders (decline)
// return_item = "Return Item" 👈 means status is updated in Agency > Order Status Page

//BACKUP 👇

{
  /* <NotificationBadge
                              className={`avatar-title bg-${getNotifBadgeColor(
                                // notif?.attributes?.order_id?.status
                                notif?.attributes?.data?.order_status
                              )} rounded-circle font-size-16`}
                            >
                              <i
                                className={`bx ${getNotifBadge(
                                  // notif?.attributes?.order_id?.status
                                  notif?.attributes?.data?.order_status
                                )}`}
                              />
                            </NotificationBadge> */
}

// const getNotifBadge = orderStatus => {
//   if (orderStatus === "pending") {
//     return "bx-cart" // Your order is placed
//   } else if (orderStatus === "order_processing") {
//     return "bx-box" //Your order is being processed
//   } else if (orderStatus === "out_for_delivery") {
//     return "bx-car" //Your order is being delivered
//   } else if (orderStatus === "delivered") {
//     return "bx-badge-check" //Your order has been delivered
//   } else {
//     return "bx-error-alt" // Resource not found. 404 API error
//   }
// }
